// author: Victor K.
@import '../../assets/styles/mixins.scss';

.activePicture {
  width: 100%;
  aspect-ratio: 1.775 / 1;
  // aspect-ratio: 16 / 9;
  background-color: $color-f4;
  // background-color: $color-d2;
  border-radius: 10px;
  object-fit: contain;
}

.picturesList {
  margin-top: 40px;
  border-top: 1px solid $color-d2;
  padding-top: 40px;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;

  @include for-desktop-sm {
    grid-template-columns: repeat(5, 1fr);
  }
  @include for-tablet {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;
    padding-top: 20px;
  }
  @include for-phone {
    grid-template-columns: repeat(2, 1fr);
  }
}

.picture {
  cursor: pointer;
  width: 100%;
  aspect-ratio: 1.775 / 1;
  object-fit: contain;
  background-color: $color-f4;
  border-radius: 10px;
  box-sizing: border-box;

  &.active {
    opacity: .5;
    border: 1px solid $text-main;
  }
}

.noImages {
  font-size: 30px;
  text-align: center;
}