/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../../assets/styles/mixins.scss';

.mainPaginator {
  margin-top: 40px;
  border-top: 1px solid $color-d2;
  padding-top: 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}

.arrows {
  display: flex;
  flex-direction: row;
  gap: 20px;
  
  .arrow {
    width: 35px;
    aspect-ratio: 1/1;
    background-image: url('../../../assets/images/icon_link_arrow_16.svg');
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    cursor: pointer;
    @include ThemeBtn;

    &.left {
      transform: rotate(-180deg);
    }
  }
}

.dots {
  display: flex;
  flex-direction: row;
  gap: 7px;

  .dot {
    width: 10px;
    aspect-ratio: 1/1;
    border-radius: 20px;
    cursor: pointer;
    @include ThemeSliderDot;
    
    &.active {
      background-color: $obj-accent-2;
    }

    @include for-phone {
      width: 10px;
    }
  }
}