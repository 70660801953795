/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.bannersBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.banner {
  border-radius: 10px;
  overflow: hidden;

  &.S {
    width: calc(50% - 10px);
    aspect-ratio: 1.4 / 1;

    @include for-phone {
      width: 100%;
    }
  }
  &.M {
    width: 100%;
    aspect-ratio: 2.84 / 1;
    @include for-phone {
      aspect-ratio: 1.4 / 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}