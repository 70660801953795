/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../../assets/styles/mixins.scss';

.feedbackWrap {
  max-width: 790px;
  padding-top: 50px;
  
  h3 {
    font-size: 44px !important;
  }
}

.openerButton {
  // margin-top: 50px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;
  padding: 16px 30px;
  background: $obj-accent-1;
  border-radius: 4px;
  display: flex;
  max-width: fit-content;
  justify-content: center;
  cursor: pointer;

  @include for-phone {
    max-width: 100%;
    display: block;
    text-align: center;
  }
}