// author: Victor K.
@import '../../assets/styles/mixins.scss';

.burger {
  cursor: pointer;
  user-select: none;
  // width: 30px;
  // height: 30px;
  position: relative;
  overflow: hidden;
  display: none;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #3B3B3E;
    background: #F4F4F4;
    border-radius: 6px;
    padding: 10px 20px;
    display: block;
  }


  &::before,
  &::after {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $text-main;
    border-radius: 2px;

    animation-timing-function: cubic-bezier(0.33, 0, 0.1, 0);
    animation-duration: .5s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  &::before {
    transform-origin: left center;
    top: 10px;
    animation-name: outActiveBefore;
  }

  &::after {
    transform-origin: right center;
    right: 0;
    top: 20px;
    animation-name: outActiveAfter;
  }

  &.isActive {
    width: 30px;
    height: 30px;

    span {
      display: none;
    }
    
    &::before,
    &::after {
      display: block;
      content: '';
      animation-duration: 0s;
    }
    &::before {
      animation-name: inActiveBefore;
    }
  
    &::after {
      animation-name: inActiveAfter;
    } 
  }



  @include for-desktop-sm  {
    display: block;
    z-index: 100;
  }
}



@keyframes inActiveBefore {
  30% {
    width: 0;
  }
  49% {
    transform: rotate(0);
    top: 10px;
  }
  50% {
    top: -1px;
    transform: rotate(45deg);
    width: 0;
  }
  80%, 100% {
    top: -1px;
    transform: rotate(45deg);
    width: 150%;
  }
}

@keyframes inActiveAfter {
  30% {
    width: 0;
  }
  69% {
    transform: rotate(0);
    top: 20px;
  }
  70% {
    top: -1px;
    transform: rotate(-45deg);
    width: 0;
  }
  100% {
    top: -1px;
    transform: rotate(-45deg);
    width: 150%;
  }
}

@keyframes outActiveBefore {
  0% {
    top: -1px;
    transform: rotate(45deg);
    width: 150%;
  }
  40%, 50% {
    transform: rotate(0);
    top: 15px;
  }
}

@keyframes outActiveAfter {
  0% {
    top: -1px;
    transform: rotate(-45deg);
    width: 150%;
  }
  40%, 50% {
    transform: rotate(0);
    top: 15px;
  }
}