// author: Victor K.
@import '../../assets/styles/mixins.scss';

.tag {
  @include Tag;
  color: $color-ffb;
  padding: 7px 10px;
  border: 1px solid $color-ffb;
  border-radius: 4px;
  width: fit-content;
}