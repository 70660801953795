/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.supportGroups {
  display: grid;
  grid-template-columns: auto 720px;
  column-gap: 20px;

  margin-bottom: 60px;

  .preview {
    min-height: 100%;
    background-color: $color-f4;
    border-radius: 10px;
    background-image: url('../../assets/images/fillers/get_support.png');
    background-size: cover;
    background-position: center;
  }

  @include for-desktop-m {
    grid-template-columns: auto 505px;
  }

  @include for-desktop-sm {
    grid-template-columns: auto 380px;
  }

  @include for-tablet {
    grid-template-columns: auto 0;
    column-gap: 0px;

    .preview {
      display: none;
    }
  }
}


.groups {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include Text_14;

  .group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px;
    column-gap: 20px;
    border-radius: 10px;
    // cursor: pointer;
    background-color: $color-f4;
    
    b {
      @include Text_20;
      display: flex;
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      // background-color: $color-f4;
      background-color: white;
      border-radius: 50%;
    }
    
    // &.active,
    // &:hover {
    //   background-color: $color-f4;
      
    //   b {
    //     background-color: white;
    //   }
    // }
  }
}