/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.supportWays {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 50px;
  column-gap: 20px;

  h3 {
    @include Head2;
    @include for-phone {
      margin-bottom: 10px;
    }
  }

  @include for-tablet {
    grid-template-columns: repeat(1, 1fr);
  }

  @include for-phone {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 40px;
    div div {
      font-size: 12px !important;
      margin-top: 0;
    }
  }
}