/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.advantages {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 20px;

  @include Text_20;

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $color-f4;
    padding: 30px;
    gap: 40px;
    border-radius: 10px;

    b {
      display: flex;
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      background-color: white;
      border-radius: 50%;
    }
  }

  @include for-desktop-sm {}
  @include for-tablet {
    grid-template-columns: repeat(1, 1fr);
    .item {
      gap: 20px;
    }
  }
  @include for-phone {
    @include Text_14;
  }
}