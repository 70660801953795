// author: Victor K.
@import '../../assets/styles/mixins.scss';

.partners {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 30px;

  img {
    max-height: 45px;
  }

  @include for-desktop-sm  {
    width: 100%;
    flex-direction: row;
    justify-content: initial;
    row-gap: 20px;
    column-gap: 30px;
  }

  @include for-tablet {
    flex-wrap: wrap;
    img {
      max-height: 35px;
    }
  }

  @include for-phone {
    flex-wrap: wrap;

  }
}