/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.supportHeader {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  
  h2 {
    @include Head1-Inner;
    margin: 0 0 40px 0;
  }

  @include for-desktop-sm {}
  @include for-tablet {
    grid-template-columns: repeat(1, 1fr);
  }
  @include for-phone {
    div {
      font-size: 12px;
      div {
        padding: 10px 20px;
      }
    }
  }
}