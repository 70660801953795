@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/colors.scss';

.calendarExpander {
  display: none;
  margin-bottom: 30px;
  align-items: center;


  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background-color: $color-f4;
    padding: 16px 30px;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;

    &:before {
      content: '';
      display: block;
      width: 16px;
      aspect-ratio: 1;
      background-image: url('../../assets/images/icon_calendar.svg');
    }
  }

  @include for-desktop-max1200 {
    display: flex;
    flex-direction: row;
    gap: 20px ;
  }

  @include for-phone {
    span {display: none;}
    .button {
      width: 100%;
    }
  }
}

.calendarWrap {
  border: 1px solid $color-d2;
  border-radius: 10px;
  padding: 40px;
  height: max-content;

  @include for-desktop-max1200{
    display: none;
    &.showInMobile {
      display: block !important;
      margin-bottom: 40px;

      position: absolute;
      top: 0;
      left: 0;
      border: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      background: rgba(0,0,0,0.5);
      border-radius: 0;
      z-index: 150;
      padding: 350px 10px 0;

      .calendarContainer {
        border-radius: 10px;
        max-width: 550px;
        padding: 30px 10px;
        background-color: white;
        box-sizing: content-box;
      }
    }
  }
  @include for-phone {
    &.showInMobile {
      padding: 300px 10px 0;
    }
  }
  @include for-phone-s {
    &.showInMobile {
      padding: 300px 0px 0;
    }
  } 
}

.mobileControls {
  display: none;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-d2;
  margin-bottom: 20px;

  h3 {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $text-main;

    @include for-phone {
      font-size: 18px;
    }  
  }

  .close {
    width: 50px;
    aspect-ratio: 1;
    background-color: $color-f4;
    border-radius: 5px;
    background-image: url('../../assets/images/icon_bold_cross_20.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    cursor: pointer;

    @include for-phone {
      width: 35px;
      background-size: 10px;
    }  
  }

  @include for-desktop-max1200{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}

.calendarContainer {
  position: relative;
  aspect-ratio: 1/.994;
  
  @include for-desktop-m {
    aspect-ratio: 1/1.027;
  }
  @include for-desktop-sm {
    aspect-ratio: 1/.95;
  }
  @include for-phone {
    aspect-ratio: 1/1;
  }
  @include for-phone-s {
    aspect-ratio: 1/1.1;
  } 
}

.calendar {
  height: 100%;
  width: 100%;
  background-color: white;
}
