// author: Victor K.
@import '../../assets/styles/mixins.scss';

.contacts {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  max-width: 280px;

  a {
    @include LinkMenu(false);
    color: $text-white;
    @include ThemeNavLink;
    
    @include for-tablet  {
      font-size: 18px;
    }
    
    @include for-phone {
      font-size: 16px;
    }
  }

  .mailNphone,
  .socials {
    display: inherit;
    column-gap: 10px;
    
    @include for-tablet  {
      // flex-direction: column;
      row-gap: 15px;
    }
  }

  @include for-tablet  {
    // padding: 75px 0;
  }

  @include for-phone {
    // padding: 50px 0 20px;
    // flex-direction: column;
    // row-gap: 40px;
  }
}

.socials {
  
  display: flex;
  margin-top: 15px;
  column-gap: 10px;

  .rounded {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #2A2A2C;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fullContacts {
  display: flex;
  flex-direction: row;
  row-gap: 15px;
  justify-content: space-between;

  padding: 0;

  .socials {
    filter: invert(1);
    .rounded {
      opacity: .5;
    }
  }

  .info {
    max-width: 700px;
    width: 100%;

    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $text-main;

    display: flex;
    flex-direction: column;
    row-gap: 50px;

    .subTitle {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: $color-be;
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: none;
    color: $text-main;
    word-break:break-all;
  }


  // @include for-desktop-sm  {
  //   margin-left: 0;
  //   margin-top: 60px;
  // }

  // @include for-tablet  {
  @media screen and (max-width: 920px)  {
    flex-direction: column;
    row-gap: 60px;

    .info {
      font-size: 44px;
    }
  }

  @include for-phone {
    row-gap: 30px;

    .info {
      font-size: 20px;
      row-gap: 30px;

      .subTitle {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }
}
