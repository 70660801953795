.todoList {
  margin-top: 20px;
}


.pages {
  display: flex;
  margin-top: 20px;

  .page {
    display: flex;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
    
  }
  .active {
    color: red;
    font-weight: bold;    
  }
}