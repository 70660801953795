/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.gBlocks {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  div {
    padding: 30px;
    font-size: 14px;
  }
  @include for-phone {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

.openerWrap {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  .opener {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }
  @include for-phone {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}

