// author: Victor K.
@import '../../assets/styles/mixins.scss';

.accentBlock {
  @include GraySquare;
  @include Text_16;
  justify-content: flex-start;
  b {
    color: $text-accent-1;
    font-size: 62px;
    line-height: 100%;
    margin-bottom: 20px;
  }
}