// author: Victor K.
@import '../../../assets/styles/mixins.scss';

.infoItem {
  h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: rgba(0,0,0,.3);
    margin-top: 0;
  }

  div {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: $text-main;
    margin-top: 15px;
    @include for-phone {
      font-size: 16px;
    }
  }

  span {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: -0.01em;
    color: $text-c6;
    margin-top: 15px;
    @include for-phone {
      font-size: 11px;
    }
  }
}