// author: Victor K.
@import '../../assets/styles/mixins.scss';

.externalDoc {
  @include Text_20;
  text-decoration: none;
  padding: 30px 20px 30px 30px;
  display: flex;
  column-gap: 44px;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid $color-d2;
  border-radius: 10px;
  @include ThemeNavLink;

  &:before {
    content: '';
    // align-self: flex-start;
    min-width: 50px;
    width: 50px;
    height: 50px;
    background-image: url('../../assets/images/icon_dwnld_19.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color-f4;
    border-radius: 50%;
  }

  @include for-desktop-sm {
    column-gap: 34px;
  }

  @include for-phone {
    padding: 15px;
    column-gap: 10px;

    &:before {
      min-width: 38px;
      width: 38px;
      height: 38px;
      background-size: 18px;
    }
  }
}