// author: Victor K.
@import '../../assets/styles/mixins.scss';

.button {
  display: flex;
  height: 48px;
  width: auto;
  box-sizing: border-box;
  padding: 0 30px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: $text-main;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
}

.color {
  background-color: $color-ffc;
}

.bw {
  background-color: $color-f4;
}