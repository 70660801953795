// author: Victor K.
@import '../../assets/styles/mixins.scss';

.poster {
  width: 100%;
  padding-top: 67%;
  position: relative;
  margin-bottom: 15px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}