/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.quote {
  padding: 40px;
  background-color: $color-f4;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 125px;

  .quoteText {
    padding: 20px 60px;
    background-repeat: no-repeat;
    background-image:
      url('../../assets/images/vector_que_open1.svg'),
      url('../../assets/images/vector_que_close1.svg');
    background-position: left top, right bottom;

    h2 {
      @include BigText;
      font-size: 30px;
      margin: 0;
      text-align: justify;
    }
    p {
      @include Text_16;
      margin: 30px 0 0 0;
    }
  }

  .quoteInfo {
    min-width: 300px;
    width: 300px;
    img {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
      border-radius: 50%;
    }
    .author {
      @include Head3;
      margin-bottom: 15px;
    }
    .position {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      @include for-phone {
        font-size: 12px;
      }
    }
  }


  @include for-desktop-m {
    column-gap: 78px;
    .quoteText {
      h2 {font-size: 30px;}
    }
  }
  
  @include for-desktop-sm {
    column-gap: 10px;
  }
  
  @include for-tablet {
    padding: 20px;
    flex-direction: column;
    .quoteText {
      h2 {font-size: 24px;}
      padding: 40px 20px;
      background-size: 30px 30px;
    }
  }
  
  @include for-phone {
    padding: 10px;
    display: block;
    .quoteText {
      h2 {font-size: 18px;}
      p {font-size: 14px;}
      padding: 30px 10px;
      background-size: 30px 30px;
      // word-break: break-all;
    }
  }
}