/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.advantages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 50px;

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: $color-3b;

  img {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
    display: block;
  }

  @include for-desktop-sm {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 50px;
  }

  @include for-tablet {
    font-size: 18px;
  }

  @include for-phone {
    grid-template-columns: repeat(1, 1fr);
    font-size: 17px;
  }
}