// author: Victor K.
@import '../../assets/styles/mixins.scss';

.nextProject {
  height: 90vh;
  font-size: 7vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $text-white;
  text-decoration: none;
  background: lightgray;
  span {
    padding-bottom: 10px;
    border-bottom: 3px solid white;
  }
}