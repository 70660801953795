/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.policyPage {
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 110%;
    letter-spacing: -0.03em;
    color: #000000;
    margin-top: 0;
    margin-bottom: 30px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #000000;
    
    ul {
      list-style-type: '- ';
      margin: 0;
      padding: 0 0 0 26px;
    }

    a {
      color: #000000;
    }
  }
}

.title {
  font-size: 74px !important;
}