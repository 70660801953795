// author: Victor K.
@import '../../assets/styles/mixins.scss';

.footerMenu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 15px;
  max-height: calc(35px * 5);
  width: 490px;
  align-content: space-between;
  column-gap: 20px;

  @include for-desktop-max1200  {
    width: 440px;
    font-size: 15px;
  }
  
  @include for-tablet {
    flex-direction: column;
    max-height: initial;
    flex-wrap: initial;
  }
  
  @include for-phone {
  }
}

.menuItem {
  @include ThemeNavLink;
}