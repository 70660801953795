// author: Victor K.
@import '../../assets/styles/mixins.scss';

.header {
  padding-top: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.content {
  // max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  // position: relative;
  z-index: 1;
  gap: 30px;

  padding-bottom: 20px;
  border-bottom: 1px solid $obj-border;

  @include for-phone {
    img {
      width: 140px;
    }
  }
}

.rightSide {
  display: flex;
  gap: inherit;
  position: relative;

  @include for-desktop-sm {
    position: initial;
    flex-direction: row-reverse;
  }
}