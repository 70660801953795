// author: Victor K.
@import '../../assets/styles/mixins.scss';

.wrapper {
  margin: 20px 0 0 0;

  @include for-phone {
    display: none;
  }
}

.content {
  color: $color-d2;
  display: flex;
  column-gap: 5px;
  align-items: flex-start;
  flex-wrap: wrap;

  a {
    color: inherit;
    text-decoration: none;

  }
  .active {
    color: $color-ffb;
  }

  .additional {
    user-select: none;
    
    @include for-desktop-sm {
      max-width: 65%;
      overflow: hidden;
      line-break: anywhere;
      height: 1.1rem;
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: 0px;
        padding-left: 5px;
        background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, white 25%);
        content: '...';
        color: inherit;
      }
    }
  }
}