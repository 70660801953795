// author: Victor K.
@import '../../assets/styles/mixins.scss';

.field {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 100%;
  color: $text-c6;
  margin-bottom: 60px;
  padding-bottom: 20px;
  border-bottom: 1px solid $text-c6;
  position: relative;

  label {
    position: absolute;
  }

  select {
    border: 0;
    width: 100%;
    font-size: inherit;
    color: inherit;
    outline: none;
    color: gainsboro;
    cursor: pointer;
    -webkit-appearance: none;
    background-color: white;

    option {
      color: black;
      &:disabled {
        color: $text-c6;
      }
    }

    &.filled {
      color: $text-main;
    }
  }
  
  .error {
    position: absolute;
    bottom: -30px;
    font-size: 12px;
    color: darken($text-c6, $amount: 10);
  }

  &:after {
    display: block;
    content: '';
    right: 0;
    top: 25%;
    width: 24px;
    height: 15px;
    background-color: white;
    position: absolute;
    @include bgImage('icon_chevron_24x12.svg');
    background-size: contain;
  }

  @include for-tablet  {
    font-size: 26px;
    margin-bottom: 40px;

    .error {
      bottom: -25px;
      font-size: 10px;
    } 
  }

  // @include for-phone  {
  //   margin-left: 0;
  // }
}