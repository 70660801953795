/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.personalList {
  margin-top: 150px;
  color: $text-main;

  h3 {
    margin: 0 0 50px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 100%;
    letter-spacing: -0.02em;
  }

  @include for-desktop-sm {
    margin-top: 120px;
  }
  @include for-tablet {
    margin-top: 100px;
  }
  @include for-phone {
    margin-top: 80px;
    h3 {
      font-size: 26px;
      margin: 0 0 40px 0;
    }
  }
}


.persItem {
  display: grid;
  grid-template-columns: 2fr 1.5fr 150px;
  column-gap: 20px;
  padding: 40px 0;
  border-bottom: 1px solid $color-d2;
  background-image: url('../../assets/images/icon_big_arrow_60x15.svg');
  background-repeat: no-repeat;
  background-position: right center;
  cursor: pointer;
  
  .name {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: -0.02em;
    position: relative;
    @include for-tablet {
      margin-bottom: 10px;
    }
    @include for-phone {
      font-size: 20px;
    }
  }

  .position {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    @include for-desktop-sm {
      font-size: 14px;
    }
  }

  .photo {
    display: none;
    // border: 1px solid black;
    position: absolute;
    width: 150px;
    height: 200px;
    top: -55px;
    right: 0;
    overflow: hidden;
    z-index: 1;
    img {
      // background-color: rgba(0,0,0,1);
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
  }

  &:hover {
    .photo {
      display: block;
    } 
    @include for-tablet {
      .photo {
        display: none;
      } 
    }

  }

  @include for-tablet {
    display: flex;
    flex-direction: column;
    padding-right: 150px;
  }
  @include for-phone {
    // margin-bottom: 40px;
    background-position: left 80%;
    padding: 30px 0 70px 0;
  }
}



