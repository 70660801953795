/* for-desktop-sm = 1000px; */
/* for-tablet = 720px; */
/* for-phone = 480px; */
/* for-small = 320px; */


.rbc-toolbar {
  position: relative;
  margin-bottom: 30px;
}
@media screen and (max-width: 720px) {
  .rbc-toolbar {
    margin-bottom: 20px;
  }
}

.rbc-toolbar .rbc-toolbar-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #3B3B3E;
}
@media screen and (max-width: 720px) {
  .rbc-toolbar .rbc-toolbar-label {
    font-size: 20px;
  }
}

.rbc-toolbar
  .rbc-btn-group:nth-child(1)
    button:nth-child(1) {
      display: none;
}

.rbc-toolbar .rbc-btn-group:nth-child(1) button:nth-child(2),
.rbc-toolbar .rbc-btn-group:nth-child(1) button:nth-child(3) {
      width: 50px;

      color: rgba(0,0,0,0) !important;
      background-color: rgba(0,0,0,0) !important;
      border: none !important;
      position: absolute;

      cursor: pointer;

      top: 0;
      background-repeat: no-repeat;
      background-position: center;
}

.rbc-toolbar
  .rbc-btn-group:nth-child(1)
    button:nth-child(2) {
      left: 0;
      background-image: url('../../assets/images/icon_calend_arrow_16.svg');
      transform: scaleX(-1);
}

.rbc-toolbar
  .rbc-btn-group:nth-child(1)
    button:nth-child(3) {
      right: 0;
      background-image: url('../../assets/images/icon_calend_arrow_16.svg');
}

.rbc-toolbar .rbc-toolbar-label {
  text-transform: capitalize;
}

.rbc-month-header {
  margin-bottom: 20px;
}
@media screen and (max-width: 480px) {
  .rbc-month-header {
    margin-bottom: 0px;
  }
}


@media screen and (max-width: 480px) {
  .rbc-header {
    font-size: 14px;
  }
}

.rbc-off-range-bg {
  background: initial;
}
.rbc-off-range {
  opacity: .3;
}

.rbc-month-view {
  border: none !important;
  row-gap: 10px;
}
.rbc-month-view * {
  border: none !important;
}

.rbc-row-content,
.rbc-row-content .rbc-row {
  height: 100%;
}
.rbc-row-content {
  display: flex;
  flex-direction: column;
  position: relative;
}
.rbc-row-content .rbc-row:not(:nth-child(1)) {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 10%;
  /* z-index: -1; */
}

.rbc-date-cell {
  padding: 0;
}
.rbc-date-cell .rbc-button-link {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #3B3B3E;
}
@media screen and (max-width: 480px) {
  .rbc-date-cell .rbc-button-link {
    font-size: 12px;
  }
}

.rbc-row-bg,
.rbc-row {
  column-gap: 4px;
}

.rbc-today {
  background-color: #F4F4F4;
  border-radius: 10px;
}
/* .rbc-current is selected date */
.rbc-current {
  box-sizing: border-box;
  background-color: #FFB53F;
  border-radius: 10px;
  margin: 0 -1px;
}
.rbc-row-segment {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0 -1.6px;
  z-index: -1;
}

.rbc-event {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0;
  aspect-ratio: 1;
  background-color: rgba(0,0,0,0) !important;
  font-size: 0px;
  outline: none !important;
  position: relative;
  z-index: 0;
  /* height: 0; */
}
.rbc-event:after {
  position: absolute;
  margin-bottom: 2px;
  content: '';
  display: block;
  width: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #FFB53F !important;
}
@media screen and (max-width: 480px) {
  .rbc-event {
    margin-bottom: 0px;
  }
  .rbc-event:after {
    width: 5px;
  }
}

.rbc-button-link.rbc-show-more {
  display: none;
}