// author: Victor K.
@import '../../assets/styles/mixins.scss';

.linkBigFeedback {
  display: block;
  @include Head2-Inner;
  text-decoration: none;

  @include for-desktop-sm  {
    font-size: 11.5vw;
  }
}

.bigFeedback {
  margin: 0 !important;
  background-color: $bg-lgrey;
  padding: 60px 20px;
  position: relative;
  &::before {
    transition: .35s width ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: $bg-lblack; 
    content: '';
  }
  
  &:hover {
    .content {
      filter: invert(1);
    }
    &::before {
      width: 100%;
    }
  }
}

.content {
  transition: .35s all ease-in-out;
  background-image: url('../../assets/images/_to_remove.png');
  background-repeat: no-repeat;
  background-position: right center;

  @include for-desktop-sm  {
    background-size: 11.5vw;
  }
}
