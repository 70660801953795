/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.totalCount {
  @include Text_20;
  margin-top: 40px;
  margin-bottom: 20px;
}

.mediaContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
}