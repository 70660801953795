// author: Victor K.
@import '../../assets/styles/mixins.scss';

.feedbackForm {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin-left: auto;
  margin-top: 100px;

  @include for-desktop-sm  {
    margin-left: 0;
    margin-top: 60px;
  }
}

.feedbackForm.small {
  margin-top: 50px;
  margin-left: 0;
  max-width: 100%;

  .field {
    font-size: 20px;
    padding-bottom: 10px;
    margin-bottom: 40px;
  }
}

.attach {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: -0.02em;
  cursor: pointer;
  margin-bottom: 60px;

  @include for-tablet  {
    font-size: 26px;
  }
}

.assign {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.03em;
  margin-bottom: 60px;

  input {
    margin-right: 10px;
  }

  a {
    color: inherit;
  }

  @include for-tablet  {
    font-size: 14px;
  }
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  gap: 10px;
  
  width: 267px;
  height: 64px;
  background: $color-ffc;
  border: none;
  border-radius: 4px;
  @include ThemeBtn2;

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */
  
  letter-spacing: -0.02em;
  color: $text-main;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}