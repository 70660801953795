/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.errPage {
  margin-top: 150px;
}

.errContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  .text {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 66px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $text-main;
    margin-bottom: 40px;

    @include for-tablet {
      font-size: 44px;
    }

    @include for-phone {
      font-size: 28px;
    }
  }


  .link {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 30px;
    gap: 10px;
    width: max-content;

    background: $color-ffc;
    border: none;
    border-radius: 4px;
  
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;

    letter-spacing: -0.02em;
    color: $text-main;
  }
}