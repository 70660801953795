/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../../assets/styles/mixins.scss';

.employeeWrap {
  max-width: 940px;
}

.employeePopUp {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start;
  margin-top: 50px;

  .bigPic {
    max-width: 355px;
  }

  .bigPic,
  .minPic {
    border-radius: 10px;
  }
  .bigPic {
    width: 50%;
  }

  .PPname {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: $text-main;
    margin-bottom: 10px;
  }

  .PPposition {
    @include Text_14;
    color: $color-be;
    margin-bottom: 20px;
  }

  .PPDescr {
    @include Text_14;
  }

  .PPpositionDescr {
    @include Text_14;
  }

  .minPic {
    display: none;
  }

  @include for-phone {
    margin-top: 70px;

    .bigPic {
      display: none;
    }
    .minPic {
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }
  }
}