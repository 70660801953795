@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.section {
  // font-family: $defaultFont, Arial, Helvetica, sans-serif;
  color: #1C1C1C;
  ol li, ul li {
    list-style-type: inherit;
  }

  .short-info {
    font-size: 24px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #9C9C9C;
    margin: 60px 0;
  }

  p,
  .text {
    font-size: 16px;
    line-height: 150%;
    color: $text-main;
    margin-bottom: 24px;
  }

  h1, h2, h3, h4, h5 {
    margin-bottom: 30px;
    margin-top: 60px;
  }

  h1,
  .main-header {
    font-size: 40px;
  }

  h2, h3, h4, h5,
  .sub-header {
    font-size: 32px;
  }

  img {
    margin: 30px 0;
  }

  blockquote,
  .blockquote {
    margin: 30px 0;
    padding: 40px;
    background-color: $color-f4;
    border-radius: 10px;

    p {
      @include Text_20;
      padding: 20px 60px;
      margin: 0;
      background-repeat: no-repeat;
      background-image:
        url('../../assets/images/vector_que_open1.svg'),
        url('../../assets/images/vector_que_close1.svg');
      background-position: left top, right bottom;
    }
  }

  .sub-scriptum {
    font-size: 16px;
    line-height: 150%;
    color: #969696;
  }

  iframe {
    min-height: 500px;
  }
  pre {
    white-space: pre-line;
  }

  * {
    // font-family: $defaultFont, Arial, Helvetica, sans-serif;
  }
}


@include for-tablet {
  .section {
    .short-info {
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.02em;
    }
  
    p,
    .text {
      font-size: 16px;
      line-height: 150%;
      color: $text-main;
      margin-bottom: 24px;
    }
  
    h1, h2, h3, h4, h5 {
      margin-bottom: 30px;
      margin-top: 60px;
      line-height: 120%;
    }
  
    h1,
    .main-header {
      font-size: 30px;
    }
  
    h2, h3, h4, h5,
    .sub-header {
      font-size: 24px;
    }
  
    blockquote,
    .blockquote {
      font-size: 14px;
      padding: 40px 20px;
    }

    .sub-scriptum {
      font-size: 14px;
    }
  }
}

@include for-phone {
  .section {
    .short-info {
      font-size: 20px;
      line-height: 150%;
      letter-spacing: 0.02em;
    }
  
    p,
    .text {
      font-size: 14px;
      line-height: 150%;
      color: $text-main;
      margin-bottom: 24px;
    }
  
    h1, h2, h3, h4, h5 {
      margin-bottom: 30px;
      margin-top: 60px;
      line-height: 120%;
    }
  
    h1,
    .main-header {
      font-size: 30px;
    }
  
    h2, h3, h4, h5,
    .sub-header {
      font-size: 24px;
    }
  
    blockquote,
    .blockquote {
      padding: 10px;
      p {
        font-size: 14px;
        padding: 60px 10px;
      }
    }

    .sub-scriptum {
      font-size: 14px;
    }
  }
}