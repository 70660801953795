/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.bigImg {
  width: 100%;
  overflow: hidden;
  margin-bottom: 150px;

  img {
    width: 100%;
    border-radius: 10px;
  }

  @include for-desktop-sm {
    margin-bottom: 120px;
  }

  @include for-tablet {
    margin-bottom: 100px;
  }

  @include for-phone {
    margin-bottom: 80px;
  }
}