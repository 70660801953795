/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.headerBlock {
  display: flex;
  flex-direction: row;
  column-gap: 140px;
  align-items: flex-start;

  .headerText {
    width: calc(100% - 550px);
  }

  h2 {
    @include Head1-Inner;
    margin: 0 0 40px 0;
  }

  .descr {
    @include Text_20;
  }

  @include for-desktop-m {
    column-gap: 20px;
    .headerText {
      width: calc(100% - 400px);
    }
  }

  @include for-desktop-sm {
    .headerText {
      width: 100%;
    }
    row-gap: 60px;
    flex-direction: column;
  }


  @include for-phone {
    flex-direction: column;
    row-gap: 60px;
    h2 {
      font-size: 40px;
    }
  }
}