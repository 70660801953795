// author: Victor K.
@import '../../assets/styles/mixins.scss';

.styledLink {
  text-decoration: none;
  @include Arrow-btn;
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 15px;
  
  &.hoverable {
    @include ThemeArrowText;
    &:hover:after {
      background-image: url('../../assets/images/icon_link_arrow_16_w.svg');
    }
  }

  &:after {
    content: '';
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: white;
    background-image: url('../../assets/images/icon_link_arrow_16.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  @include for-phone {
    font-size: 14px;
  }
}