/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

$maxWidth: 940px;

.content {
  position: relative;
  min-height: 600px;
}

.blockTitle {
  border: none;
  padding: 0;
  margin: 0 0 30px 0;
  h2 {
    @include Head1-Inner;
    max-width: $maxWidth;
    font-size: 52px;

    @include for-tablet {
      font-size: 40px;
    }

    @include for-phone {
      font-size: 30px;
    }
  }
}


.article {
  max-width: $maxWidth;
}

.meta {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  div {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $text-main;
    border-radius: 50px;
    padding: 16px 22px;

    @include for-phone {
      font-size: 12px;
      padding: 12px 15px;
    }
  }


  .date {
    background-color: $color-f4;
  }

  .tag {
    background-color: $obj-accent-1;
  }
}

.poster {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 30px;
}

.lastAddedList {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% - $maxWidth);
  padding-left: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;


  h3 {
    @include Head3;
    margin: 0;
  }

  @include for-desktop-m {
    display: none;
  }


  .last {
    text-decoration: none;
    background-color: $color-f4;
    border-radius: 10px;
    padding: 20px;

    div {
      display: flex;
      align-items: center;
      gap: 60px;
      margin-bottom: 15px;
    }
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: $text-main;
    }
  }
}