/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.chart {
  width: 100%;
  max-width: 550px;
  position: relative;

  .donutWrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;

    .donut {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
    }
    
    &:after {
      $side: 60%;

      display: block;
      position: absolute;
      top: calc((100% - $side)/2);
      left: calc((100% - $side)/2);
      width: $side;
      height: $side;
      border-radius: 50%;
      content: '';
      background: white;
    }
  }

  .donutLegend {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #909190;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 70px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: $color-d2;
      margin: 30px 0 10px 0;

      b {
        color: $color-ffb;
      }
    }
    @include for-phone {
      width: 100%;
      font-size: 12px;
      h3 {
        margin-top: 0;
        font-size: 36px;
      }
    }
  }


  @include for-desktop-sm {
    margin: 0 auto;
  }
}