// author: Victor K.
@import '../../assets/styles/mixins.scss';

.mediaItem {
  text-decoration: none;
  width: 100%;
  cursor: pointer;
  display: block;
  color: $color-3b;
  // @include ThemeNavLink;
}
.details {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 60px;
  margin-bottom: 15px;
}

.title {
  @include Head4;
  margin: 0;
  color: inherit;
}

.descr {
  margin-top: 30px;
  @include Text_16;
  color: $color-90;
}



.fixedTitle {
  height: 96px;
  overflow: hidden;
  @include for-desktop-m {
    height: initial;
    overflow: initial;
  }
}