/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.errPage {
  margin-bottom: 50px;
  
  >div {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // margin: 0;
  }
}

.text {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.03em;
}