// @import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

@mixin flexbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  @include flexbox;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 10px;
  padding: 0;
  margin-top: 80px;
}

.prev, .next {
  a {
    display: block;
    padding-left: 30px;
    padding-bottom: 30px;
    background-image: url('../../assets/images/icon_calend_arrow_16.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
}

.prev {
  transform: scale(-1);
}

.btn {
  $size: 30px;

  width: $size;
  height: $size;
  @include flexbox;

  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  color: $text-main;
  transition: 0.2s color;

  &.active {
    color: $color-ffb;
  }

  &:focus {
    outline: none;
  }
}

.label {
  font-size: 14px;
  font-weight: 600;
  color: $text-main;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.disabledLabel {
  opacity: 0.5;
}

@include for-tablet {
  .container {
    margin-top: 60px;
  }
}
