// author: Victor K.
@import './colors.scss';
@import './theme.scss';
@import './animates.scss';

@mixin MainBlocksWrapper {
  padding: 0 90px;
  margin-top: 50px;
  margin-bottom: 150px;

  @include for-desktop-m {
    padding: 0 20px;
    margin-bottom: 120px;
  }

  @include for-tablet {
    margin-bottom: 100px;
  }

  @include for-phone {
    padding: 0 10px;
    margin-top: 30px;
    margin-bottom: 80px;
  }
}

@mixin MainBlocksContent {
  max-width: 1400px;
  margin: 0 auto;

  @include for-desktop-m {
    // max-width: 1200px;
  // padding: 100px 0;
  }

  @include for-desktop-sm {
    // padding: 100px 0;
  }

  @include for-phone {
    // padding: 60px 0;
  }
}

@mixin Head1(){
  font-style: normal;
  font-weight: 500;
  font-size: 76px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;

  
  @include for-desktop-sm {
    font-size: 46px;
    margin-bottom: 40px;
  }

  @include for-tablet {
    font-size: 40px;
  }

  @include for-phone {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

@mixin Head2(){
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;
  margin: 0;
  @content;

  @include for-phone {
    font-size: 18px;
  }
}

@mixin Head3(){
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;
}

@mixin Head4(){
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;

  @include for-tablet {
    font-size: 14px;
  }
}

@mixin Service_Head(){
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;
}

@mixin Head1-Inner(){
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 66px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;

  // @include for-phone {
  //   font-size: 40px;
  //   line-height: 100%;
  // }
}

@mixin Head2-Inner() {
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;

  // @include for-tablet {
  //   font-size: 60px;
  // }

  // @include for-phone {
  //   font-size: 40px;
  // }
}

@mixin Service_Head-Inner() {
  font-style: normal;
  font-weight: 500;
  font-size: 44px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;

  @include for-tablet {
    // font-size: 60px;
  }

  @include for-phone {
    // font-size: 40px;
  }
}

@mixin BigText() {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;
}

@mixin Text_20() {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;
}

@mixin Text_14() {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: $text-main;
  @content;
}

@mixin Text_16() {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $text-main;
  @content;
}

@mixin Arrow-btn() {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;
}

@mixin Controls() {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;
}

@mixin Tag() {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;
  @content;

  @include for-desktop-sm {
    // font-size: 14px;
  }

  @include for-phone() {
    // font-size: 12px;
  }
}

@mixin GraySquare() {
  background-color: $color-f4;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px; 
  @content;

  @include for-desktop-m {
    padding: 30px; 
  }

  @include for-tablet {
    padding: 20px;
  }
}

// delete??

@mixin ProjText() {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: $text-main;
  @content;
}


@mixin PrevText() {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 110%;
  letter-spacing: -0.03em;
  color: $text-main;
  @content;

  @include for-desktop-sm {
    font-size: 24px;
    // margin-bottom: 40px;
  }

  @include for-phone {
    font-size: 20px;
    // margin-bottom: 10px;
  }
}

@mixin ServText() {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: $text-main;
  @content;
}

@mixin InputText() {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: $text-main;
  @content;
}

@mixin RequestButton() {
  font-style: normal;
  font-weight: 500;
  font-size: 106px;
  line-height: 110%;
  color: $text-main;
  @content;
}

@mixin LinkMenu($anim: false) {
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;

  text-decoration: none;
  
  @if ($anim) {
    @include AnimUnderline;
  }

  @content;
}



@mixin bgImage(
  $name,
) {
  background-image: url('../../assets/images/'+$name);
  background-position: center;
  background-repeat: no-repeat;
  @content;
}



@mixin mainBackground() {
  // @media screen and (min-width: 1200px) {}
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  background-position-y: center;
  @content;
}

@mixin BlockSelection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

@mixin UnblockSelection {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text;   /* Chrome/Safari/Opera */
  -khtml-user-select: text;    /* Konqueror */
  -moz-user-select: text;      /* Firefox */
  -ms-user-select: text;       /* Internet Explorer/Edge */
  user-select: text;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

@mixin GeneralInput {
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 16px 19px;
  border: 1px solid $bg-input;
  text-align: left;
  background-color: $bg-input;
  color: $text-dgrey;

  &:hover {
    background-color: $bg-input-hover;
  }

  &:focus {
    background-color: $bg-input-hover;
    color: $text-black;
  }
  @content;
}

@mixin for-desktop {
  @media screen and (min-width: 1401px) {
    @content;
  }
}

@mixin for-desktop-m {
  @media screen and (max-width: 1400px) {
    @content;
  }
}

@mixin for-desktop-max1200 {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin for-desktop-sm {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

@mixin for-tablet {
  @media screen and (max-width: 720px) {
    @content;
  }
}

@mixin for-phone {
  @media screen and (max-width: 480px) {
    @content;
  }
}

@mixin for-phone-s {
  @media screen and (max-width: 320px) {
    @content;
  }
}