/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.content {
  div {
    max-width: 820px;
  }

  h2 {
    border: none;
    padding: 0;
    margin-bottom: 40px;
  }
}
