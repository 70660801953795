/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.advantages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: $color-3b;

  b { margin-bottom: 100px;}

  @include for-desktop-sm {
    b { margin-bottom: 50px;}
  }
  @include for-tablet {
    b { margin-bottom: 40px;}
    gap: 10px;
    grid-template-columns: repeat(1, 1fr);
  }
  @include for-phone {
    b {font-size: 56px;}
    font-size: 14px;
  }
}