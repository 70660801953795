// author: Victor K.
@import '../../assets/styles/mixins.scss';

.footer {
  margin: 0 0 0 0 !important;
  background-color: $bg-3b;
  color: $text-white;
  border-radius: 10px 10px 0px 0px;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 50px;
  flex-wrap: wrap;
  row-gap: 60px;
  column-gap: 50px;

  @include for-desktop-m  {
    column-gap: 20px;
  } 
}