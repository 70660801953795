// author: Victor K.
@import '../../assets/styles/mixins.scss';

.search {
  &Wrapper { }
  
  &Switcher {
    background-color: $color-f4;
    border-radius: 50%;
    cursor: pointer;
    @include bgImage('icon_search.svg');
    position: relative;
    @include ThemeBtn;

    &.close {
      @include bgImage('close_cross.svg');
      background-size: 13px;
    }

    b {
      display: block;
      width: 35px;
      height: 35px;

    }
    .mobile {
      display: none;
    }

    @include for-desktop-sm {
      .mobile {
        display: block;
      }
      .desktop {
        display: none;
      }
    }
  }
}

.topSearch {
  position: absolute;
  top: -25%;
  right: 50px;
  padding: 0 13px 0 20px;
  border-radius: 10px;
  box-sizing: border-box;
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: calc(100% - 45px);
  height: 50px;
  background-color: $color-f4;

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: $text-main;
  border: none;

  input {
    background-color: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    width: 100%;
  
    border: none;
    outline: none;
  }

}