@import './assets/styles/fonts';
@import './assets/styles/scrollBar';

$defaultFont: 'Inter';

@import-normalize;

body,
div[id="root"] {
  margin: 0;
  font-family: $defaultFont, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div[id="root"] {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

