/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.eventInfo {
  // min-height: 100%;

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $text-main;
    margin: 0 0 40px 0;

    @include for-phone {
      font-size: 18px;
      margin: 0 0 20px 0;
    }
  }

  .poster {
    width: 100%;
    aspect-ratio: auto 16/9;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .partnersBlock {
    margin-top: 40px;
    border-top: 1px solid $color-d2;
    padding-top: 40px;
    @include for-tablet {
      margin-top: 30px;
      padding-top: 30px;
    }

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 130%;
      letter-spacing: -0.02em;
      color: $text-main;
      margin: 0 0 30px 0;
      @include for-phone {
        font-size: 18px;
      }
    }

    .partners {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 40px;

      .partner {
        height: 50px;
        aspect-ratio: auto;
        @include for-tablet {
          height: 30px;
        }
      }
    }
  }
}