/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.block2 {
  margin-top: 150px;

  @include for-desktop-sm {
    margin-bottom: 120px;
  }

  @include for-tablet {
    margin-top: 0px;
    margin-bottom: 100px;
  }

  @include for-phone {
    margin-bottom: 80px;
  }
}

.block2Title {
  @include Head2-Inner;
  width: 60%;
  margin-bottom: 60px;

  @include for-desktop-sm {}

  @include for-tablet {}

  @include for-phone {
    font-size: 26px;
    margin-bottom: 40px;
  }
}