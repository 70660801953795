// author: Victor K.
@import '../../assets/styles/mixins.scss';

.blockWrapper {
  @include MainBlocksWrapper;
}

.blockContent {
  @include MainBlocksContent;
}

.blockTitle {
  padding-bottom: 20px;
  margin-top: 0px;
  margin-bottom: 60px;
  border-bottom: 1px solid $color-d2;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-end;

  @include for-desktop-sm {
    flex-direction: column;
    align-items: initial;
  }
  
  h2 {
    @include Head1-Inner;
    margin: 0;
    
  }
  @include for-phone {
    h2 { font-size: 40px; }
    margin-bottom: 50px;
  }
}

.blockDescr {
  @include Head2{
    margin-bottom: 100px;
  };
}

// @include for-desktop-sm {}
// @include for-tablet {
//   grid-template-columns: repeat(1, 1fr);
//   .item {
//     gap: 20px;
//   }
// }
// @include for-phone {
//   @include Text_14;
// }