/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.mediaContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 50px;

  @include for-desktop-sm {
    grid-template-columns: repeat(2, 1fr);
  }

  @include for-phone {
    grid-template-columns: repeat(1, 1fr);
  }
}

.loader {
  
}