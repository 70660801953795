// author: Victor K.
@import './colors.scss';

@mixin ThemeArrowText {
  transition: all .3s;
  &::after {
    transition: all .3s;
  }
  &:hover {
    color: $obj-accent-2;
    &:after {
      background-color: $obj-accent-2;
    }
  }
}

@mixin ThemeBtn {
  transition: all .3s;
  background-color: $bg-lgrey;
  &:hover {
    background-color: $obj-accent-1;
  }
}

@mixin ThemeBtn2 {
  transition: all .3s;
  background-color: $obj-accent-1;
  &:hover {
    background-color: $obj-accent-2;
  }
}

@mixin ThemeSliderDot {
  transition: all .3s;
  background-color: $bg-d2;
  &:hover {
    background-color: $obj-accent-2;
  }
}

@mixin ThemeNavLink {
  transition: all .3s;
  // color: $text-main;
  &:hover {
    color: $text-c6;
  }
}
