/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../../assets/styles/mixins.scss';

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0,0,0,.5);
}

.sidePopUp {
  position: fixed;
  max-width: 790px;
  width: 100%;
  height: 100%;
  background: white;
  top: 0;
  right: 0;
  transition: 0.5s width;
  z-index: 1010;
  overflow-y: scroll;
  padding: 30px;
  box-sizing: border-box;
  color: $text-main;
  @include for-tablet {
    padding: 30px 20px;
  }
  @include for-phone {
    padding: 30px 10px;
  }

  &.hidden {
    display: none;
  }
  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    background-image: url('../../../assets/images/icon_bold_cross_20.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: $color-f4;
    border-radius: 6px;
    cursor: pointer;
    @include for-tablet {
      right: 20px;
    }
    @include for-phone {
      right: 10px;
      width: 35px;
      height: 35px;
    }
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.02em;
    margin: 0 100px 30px 0;

    @include for-tablet {
      font-size: 26px;
    }
    @include for-phone {
      margin: 0 45px 20px 0;
      font-size: 18px;
    }
  }

}