// author: Victor K.
@import '../../assets/styles/mixins.scss';

.services {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 60px;

  @include for-phone() {
    grid-template-columns: repeat(1, 1fr);
  }
}