// author: Victor K.
@import '../../assets/styles/mixins.scss';

.copy {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $obj-border;
  @include Tag {
    color: inherit;
  };


  @include for-phone {
    flex-direction: column;
    row-gap: 10px;
    font-size: 12px;
  }
}