// author: Victor K.
@import '../../assets/styles/mixins.scss';

.docsList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 20px;

  @include for-tablet {
    grid-template-columns: repeat(1, 1fr);
  }
}