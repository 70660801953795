// author: Victor K.
@import '../../assets/styles/mixins.scss';

.projectLink {
  width: 100%;
  height: -webkit-fill-available;
  text-decoration: none;
  opacity: 0;

  &.anim {
    @include AnimTransUp;
  }
  
  .preview {
    height: 760px;
    // 1.207;
    background-color: $bg-lgrey;
    margin-bottom: 30px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-desktop-sm {
      height: 565px;
    }

    @include for-tablet {
      height: calc(115vw);
    }

    img {
      width: 100%;
      transition: .3s all ease-in-out;
    }
  }
  .name {
    margin-bottom: 10px;
    @include PrevText;
  }
  .tag {
    @include Tag;
  }

  &:hover {
    // text-decoration: underline;
    .preview {
      img {
        transform: scale(.9);
      }
    }
  }
}