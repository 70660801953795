// author: Victor K.
@import '../../assets/styles/mixins.scss';

.controlWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.tagFilter {
  background-color: $color-f4;
  padding: 12px 30px;
  cursor: pointer;
  border: none;
  color: $text-main;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  letter-spacing: -0.02em;
  @include ThemeBtn;

  &.active {
    background-color: $obj-accent-1;
  }

  @include for-tablet {
    font-size: 15px;
  }
}