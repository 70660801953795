/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.offer {
  padding: 0;
}

.content {
  max-width: 100%;
  @include AnimTransUp{
    animation-delay: .25s;
  };
}

.offerText {
  margin-bottom: 100px;
  
  .title {
    @include Head1;
    margin-top: 0;
  }

  .arrowLink {
    @include LinkMenu;
    text-decoration: none;
    padding-right: 28px;
    background-image: url('../../assets/images/_to_remove.png');
    background-repeat: no-repeat;
    background-position: right;
  }
}

.imgAnim {
  @include AnimTransUp{
    animation-delay: .5s;
  };
  img {
    @include AnimDiagonal
  }
}