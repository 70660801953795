// author: Victor K.
$color-white: #FFF;
$color-black: #000;
$color-3b: #3B3B3E;
$color-90: #909190;
$color-be: #BEBEBE;
$color-d2: #D2D2D2;
$color-e5: #E5E5E5;
$color-f4: #F4F4F4;
$color-ffc: #FFC15F;
$color-ffb: #FFB53F;

$old-lblack: #141414;
$old-error: #FF4747;


// bg colors
$bg-black: $color-3b;
$bg-white: $color-white;
$bg-3b: $color-3b;
$bg-90: $color-90;
$bg-be: $color-be;
$bg-d2: $color-d2;
$bg-main: $color-f4;

$bg-lgrey: $color-f4;
$bg-lblack: $old-lblack;


// objects colors
$obj-main: $color-black;
$obj-border: $color-d2;
$obj-accent-1: $color-ffc;
$obj-accent-2: $color-ffb;

$obj-lblack: $old-lblack;
$obj-error: $old-error;


// text colors
$text-main: $color-3b;
$text-white: $color-white;

$text-b3: $color-90;
$text-c6: $color-be;

$text-accent-1: $color-ffc;
$text-accent-2: $color-ffb;

$text-error: $old-error;