// author: Victor K.
@import '../../assets/styles/mixins.scss';

.mediaItem {
  padding: 30px;
  background-color: $color-f4;

  text-decoration: none;
  width: 100%;
  cursor: pointer;
  display: block;
  border-radius: 10px;
  box-sizing: border-box;
}

.title {
  @include Text_20;
  margin: 0 0 20px 0;
  color: $text-main;
}

.descr {
  @include Text_14;
  color: $text-main;
  margin-bottom: 40px;
}

.details {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}