/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.owner {
  margin-bottom: 150px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: center;

  @include for-tablet {
    gap: 40px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.details {
  h3 {
    margin: 0 0 20px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 100%;
    letter-spacing: -0.02em;
    @include for-desktop-sm {
      font-size: 44px
    }
    @include for-phone {
      font-size: 20px
    }
  }

  .position {
    @include Text_16;
    color: $color-be;
    margin: 0 0 30px 0;
    @include for-phone {
      font-size: 12px;
      margin: 0 0 20px 0;
    }
  }

  .msg {
    @include Text_20;
    @include for-desktop-sm {
      font-size: 16px
    }
    @include for-phone {
      font-size: 14px
    }
  }
}

.photo {
  img {
    border-radius: 10px;
    width: 100%;
  }
  overflow: hidden;
}