/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.titleWithContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin: 110px 0;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  .content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    
    @include for-desktop-sm {
      margin-bottom: 50px;
    }
  }
  
  .children {
    max-width: 340px;
    margin-top: -10px;
    @include for-desktop-sm {
      max-width: 100%;
      margin-top: -20px;
    }
  }

  h1 {
    margin: 0;
    @include Head1-Inner;
    max-width: 50%;
    font-weight: 500;
    max-width: 800px;
  }

  .picForDesktop {
    margin: 0;
    height: 600px;
    max-width: 700px;

    img {
      height: 100%;
      object-fit: cover;
    }
    @include for-desktop-sm {
      max-width: 100%;
      width: 100%;
    }
    @include for-phone {
      display: none; 
    }
  }
  .picForMobile {
    margin: 0;
    display: none; 
    @include for-phone {
      display: block; 
    }
  }
  
  @include for-desktop-sm {
    display: block;
    margin: 35px 0;
    gap: 50px;
    h1 {
      @include Head1-Inner;
      max-width: 100%;
    }
  }
  
  @include for-tablet {
    h1 {
      margin-bottom: 0 0 20px 0;
    }
  }
  @include for-phone {
    h1 {
      font-size: 34px;
    }
  }
}