// author: Victor K.
@import '../../../assets/styles/mixins.scss';

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 40px;
  .mobile {
    display: none;
  }
}

.menuItem {
  @include LinkMenu;
  @include ThemeNavLink;

  @include for-desktop-sm {
    font-size: 40px;
  }

  @include for-tablet {
    font-size: 40px;
  }
  
  @include for-phone {
    font-size: 26px;
  }
}

.topFeedback {
  @include LinkMenu(false);
  display: flex;
  align-items: center;
  height: 58px;
  width: fit-content;
  padding: 0 40px;
  border: 1px solid $text-c6;
  border-radius: 100px;
  box-sizing: border-box;
  background-color: white;
  @include HoverBtn;

  @include for-tablet {
    width: 100%;
    font-size: 34px;
    height: 114px;
    place-content: center;
    margin-top: auto;
  }

  @include for-phone {
    font-size: 18px;
    height: 58px;
  }
}


@media screen and (max-width: 1100px) {
  .menu {
    gap: 20px
  }
}


@include for-desktop-sm {
  .menu {
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: white;
    top: 0px;
    left: 0px;
    // left: -20px;
    transform: translateX(-100%);
    transition: .5s;
    transition-property: transform;
    flex-direction: column;
    align-items: flex-start;
    justify-content: normal;
    padding: 60px 20px 40px;
    column-gap: 0;
    row-gap: 40px;
    box-sizing: border-box;

    &.openMobile {
      transform: translateX(0%);
      // display: block;
    }
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
}

@include for-phone {
  .menu {
    top: 0px;
    left: 0px;

    .menu {
      row-gap: 30px;
    }
  }
}


.dropdown {
  position: relative;

  .opened {
    position: absolute;
    top: 0;
    left: -10px;
    display: none;
    width: max-content;
    
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  &:hover .opened {
    display: flex;
  }
}

.opened {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}