/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.aboutText {
  max-width: 940px;
  margin-left: auto;

  .text {
    @include BigText;
    margin-bottom: 40px;

    @include for-desktop-m {
      max-width: 800px;
      font-size: 30px;
    }
    @include for-desktop-sm {
      font-size: 24px;
    }
    @include for-phone {
      font-size: 14px;
    }
  }

  .blocks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    .block {
      @include GraySquare;
      @include Text_16;
      justify-content: center;
      b {
        color: $text-accent-1;
        font-size: 62px;
        line-height: 100%;
        margin-bottom: 20px;
      }
    }
    @include for-phone {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @include for-desktop-m {
    max-width: 800px;
    font-size: 30px;
  }
  @include for-desktop-sm {
    font-size: 24px;
  }
  @include for-phone {
    font-size: 14px;
  }
}



.uslugi {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  
  .usluga {
    width: auto;
    @include GraySquare;
    @include Service_Head-Inner;
    text-decoration: none;
    height: 540px;
    &, * {
      transition: all .3s;
    }
    
    // @include ThemeBtn;
    // &:hover {
    //   img {
    //     display: none;
    //   }
    //   .link {
    //     display: block;
    //   }
    // }

    img {
      padding: 25px;
      width: 150px;
      box-sizing: border-box;
      height: auto;
      background: white;
      border-radius: 50%;
    }

    .serviceName {
      max-width: 400px;
      line-height: 100%;
    }
    
    .link {
      display: none;
    }

    @include for-desktop-m {
      height: 500px;
    }

    @include for-desktop-sm {
      height: 380px;
    }

    @include for-tablet {
      height: 260px;
      img {
        width: 80px;
      }
      .serviceName {
        margin-right: 0;
        font-size: 28px;
      } 
    }
    @include for-phone {
      height: 220px;
    }
  }
  @include for-phone {
    grid-template-columns: repeat(1, 1fr);
  }
}

