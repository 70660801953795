// author: Victor K.
@import '../../../assets/styles/mixins.scss';

.search {
  &Line {
    top: -25%;
    right: 50px;
    padding: 0 13px 0 20px;
    border-radius: 10px;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 50px;

    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $text-main;

    border: 1px solid $text-main;
  }

  &Input {
    background-color: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    width: 100%;

    border: none;
    outline: none;
  }


  &Button {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    &:after {
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      @include bgImage('icon_chevron_12x7.svg');
      background-position-y: 55%;
      background-color: $obj-accent-1;
      border-radius: 50%;
      transform: rotate(-90deg);
    }

    &.disabled {
      opacity: 0.5;
    }
  }
}