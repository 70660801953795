// author: Victor K.
@import '../../../assets/styles/mixins.scss';

.circleLink {
  display: block;
  width: 50px;
  height: 50px;
  background-color: #F3F3F3;
  border-radius: 50px;
  background-position: center;
  background-repeat: no-repeat;

  &.be {
    background-image: url('../../../assets/images/_to_remove.png');
  }
  &.ext {
    background-image: url('../../../assets/images/_to_remove.png');
  }

  &:hover {
    background-color: black;

    &.be {
      background-image: url('../../../assets/images/_to_remove.png');
    }
    &.ext {
      background-image: url('../../../assets/images/_to_remove.png');
    }
  }
}