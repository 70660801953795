// author: Victor K.
@import '../../assets/styles/mixins.scss';

.field {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 100%;
  color: $text-c6;
  margin-bottom: 60px;
  padding-bottom: 20px;
  border-bottom: 1px solid $text-c6;
  position: relative;

  label {
    position: absolute;
  }

  input {
    border: 0;
    width: 100%;
    font-size: inherit;
    color: inherit;
    outline: none;
    &.filled {
      color: $text-main;
    }
  }

  .error {
    position: absolute;
    bottom: -30px;
    font-size: 12px;
    color: darken($text-c6, $amount: 10);
  }


  &.warningError {
    border-bottom: 1px solid $old-error;
    .error {
      color: darken($old-error, $amount: 10);
    }
  }

  @include for-tablet  {
    font-size: 26px;
    margin-bottom: 40px;

    .error {
      bottom: -25px;
      font-size: 10px;
    } 
  }
}