/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.bigTitleBlock {
  margin-bottom: 150px;

  .title {
    @include Head2;
    background-color: $color-f4;
    padding: 35px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  @include for-desktop-m {
    margin-bottom: 120px;
  }

  @include for-tablet {
    margin-bottom: 100px;
    .title {
      padding: 35px 25px;
    }
  }

  @include for-phone {
    margin-bottom: 80px;
    .title {
      padding: 15px 10px;
      font-size: 18px;
    }
  }
}