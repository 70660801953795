/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../../assets/styles/mixins.scss';

.btns {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;

  a {
    width: 100% !important;
  }

  @include for-phone {
    flex-direction: column;
  }
}

.photo {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}