// author: Victor K.
@import '../../assets/styles/mixins.scss';

.clientInfo {
  display: flex;
  justify-content: space-between;
  
  @include for-phone {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
}

.infoText {
  display: grid;
  grid-template-columns: 40% 50%;
  column-gap: 10%;
  width: 75%;

  @include for-desktop-sm {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 60%;
  }

  @include for-phone {
    width: 100%;
  }
}

.circleLinksWrap {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

