/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.content {
  display: grid;
  grid-template-columns: 550px 1fr;
  column-gap: 40px;

  @include for-desktop-m {
    grid-template-columns: 450px 1fr;
  }

  @include for-desktop-max1200 {
    display: block;
  }
}

