/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.infoText {
  // @include Text_14;

  padding: 40px 60px;
  border: 1px solid $color-d2;
  border-left: 20px solid $obj-accent-2;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  overflow: hidden;
}
