// author: Victor K.
@import '../../assets/styles/mixins.scss';

.serviceLink {
  text-decoration: none;
  max-width: 340px;
  opacity: 0;

  img {
    transition: .5s all ease-in-out;
  }

  &.anim {
    @include AnimTransUp;
  }

  .preview {
    height: 120px;
    margin-bottom: 30px;
  }
  .name {
    @include Head3;
    margin-bottom: 20px;
  }
  .descr {
    @include ServText;
    color: $text-b3;
  }
  
  &:hover {
    img {
      filter: brightness(0);
    }
  }
}

