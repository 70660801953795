/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.eventContent {
  background-color: $color-f4;
  border-radius: 10px;
  padding: 40px 40px 1px;

  @include for-tablet {
    padding: 30px 30px 1px;
  }
  @include for-phone {
    padding: 20px 10px 1px;
  }
}

.cardPadding {
  margin-bottom: 40px;
  @include for-tablet {
    margin-bottom: 30px;
  }
  @include for-phone {
    margin-bottom: 10px;
  }
}

.meta {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 40px;
  @include for-tablet {
    margin-bottom: 30px;
  }
  .date,
  .tag,
  .link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $text-main;
    padding: 16px 22px;
    border-radius: 110px;
    background-color: white;
    border: none;

    @include for-tablet {
      font-size: 12px;
      padding: 12px 15px;
    }
  }

  .link {
    border-radius: 4px;
    text-decoration: none;
    margin-left: auto;
    @include ThemeBtn2;

    @media screen and (max-width: 530px) {
      width: 100%;
      text-align: center;
    }
  }

}

.noData {
  display: flex;
  justify-content: center;
  margin: 150px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  color: $text-main;

  span {
    max-width: 400px;
  }
}