/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

$maxWidth: 940px;

.blockContent {
  margin: 0 0 30px 0;
  padding: 0;
  border: none;
  h2 {
    @include Head1-Inner;
    max-width: $maxWidth;
    font-size: 52px;

    @include for-tablet {
      font-size: 40px;
    }

    @include for-phone {
      font-size: 30px;
    }
  }
}

.article {
  max-width: $maxWidth;
}

.meta {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  div {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: $text-main;
    border-radius: 50px;
    padding: 16px 22px;

    @include for-phone {
      font-size: 12px;
      padding: 12px 15px;
    }
  }


  .date {
    background-color: $color-f4;
  }

  .tag {
    background-color: $obj-accent-1;
  }
}

.poster {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 30px;
}